





















































































































































import Vue from 'vue';
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  TitleComponent,
  GridComponent,
  DataZoomComponent,
  LegendComponent,
  ToolboxComponent,
} from 'echarts/components';
import { PieChart, BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([
  PieChart,
  TooltipComponent,
  TitleComponent,
  CanvasRenderer,
  BarChart,
  GridComponent,
  DataZoomComponent,
  LegendComponent,
  ToolboxComponent,
]);
import {
  coccMtdsResource_1_cocc_builder_constructZj,
  coccMtdsResource_1_project_singlesBySequenceNbrSummary,
} from '@/api/projectDetail';
import { mapGetters, mapMutations } from 'vuex';
import { GetZjCountType, RefreshTreeFrom } from '@/interface/product';
import { coccMtdsResource_project_statistics } from '@/api';
import {
  coccMtdsResource_1_coccbuilder_functionMerge_config,
  mergeAnalysis,
} from '@/api/project';
import ArtificialAdjustment from '@/views/projects/detail/artificialAdjustment/index.vue';
import { subtract } from '@/utils';
const anaylesisData = [
  {
    name: '工程项目',
    value: 0,
    code: 'constructCount',
  },
  {
    name: '单项工程',
    value: 0,
    code: 'singleCount',
  },
  {
    name: '单位工程',
    value: 0,
    code: 'unitCount',
  },
  {
    name: '分部工程',
    value: 0,
    code: 'branchCount',
  },
  {
    name: '综合单价',
    value: 0,
    code: 'billDeCount',
  },
  {
    name: '清单项',
    value: 0,
    code: 'quantitiesCount',
  },
  {
    name: '定额项',
    value: 0,
    code: 'deCount',
  },
  {
    name: '材料库',
    value: 0,
    code: 'materialCount',
  },
];
const analysisPieData: AnalysisDatas[] = [
  {
    code: 'jzpp',
    name: '精准组价',
    value: 0,
  },
  {
    code: 'jspp',
    name: '近似组价',
    value: 0,
  },
  {
    code: 'neverZj',
    name: '未组价',
    value: 0,
  },
];
interface AnalysisDatas {
  code: string;
  name: string;
  value: number;
}
interface FormatterPrams {
  color: string;
  name: string;
  value: number;
  percent: number;
}
interface AnalysisType {
  gfee: number;
  safeFee: number;
  glflr: string;
  specialQd: string;
}
export default Vue.extend({
  name: 'combinedPriceAnalysis',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const pieData: AnalysisDatas[] = [];
    return {
      anaylesisData,
      analysisPieData: pieData,
      anaylesisInfo: {
        jspp: 0,
        jzpp: 0,
        neverZj: 0,
        total: 0,
      },
      adjustmentVisible: false,
      targetBidPrice: 0, // 招标控制价
      projectSinglesNames: [] as string[],
      projectSinglesPrices: [] as number[],
      mergeAnalysisInfo: null as AnalysisType | null,
    };
  },
  components: { ArtificialAdjustment },
  watch: {
    visible: {
      handler(val, oldVal) {
        if (val && val !== oldVal) {
          this.getZjCount();
          this.findCombinedPriceConfig();
          this.getSingleSummary();
        }
      },
      immediate: true,
    },
    projectBidSequenceNbr: {
      handler(val, oldval) {
        if (val && val !== oldval) {
          this.getZjCount();
          this.getSingleSummary();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters([
      'projectBidSequenceNbr',
      'refreshTreeFrom',
      'currentTreeInfo',
      'tenderTotal',
      'listGenerating',
      'isThereReplace',
      'isQuotaLibraryIdentify',
    ]),
  },
  methods: {
    subtract,
    ...mapMutations(['SET_RefreshTree', 'SET_CURRENT_TREE_INFO']),
    goAdjustment() {
      if (this.listGenerating) {
        this.$message.warning('项目工程量投标清单正在生成中');
        return false;
      }
      if (this.isThereReplace) {
        this.$message.warning('项目清单有变更请先进行重新计价');
        return;
      }
      if (!this.projectBidSequenceNbr) {
        this.$message.warning('您还没有完成组价，请先进行一键组价');
        return false;
      }
      this.$store.commit('SET_COMBINED_SUCCESS_VISIBLE', false);
      this.adjustmentVisible = true;
    },
    resultHandle() {
      this.SET_RefreshTree(RefreshTreeFrom.COMBINED_PRICE);
      setTimeout(() => {
        this.getLastChildData(this.currentTreeInfo);
        this.$store.commit('SET_SELECTSTATUS', true);
      }, 1000);
      this.$store.commit('SET_COMBINED_SUCCESS_VISIBLE', false);
    },
    getLastChildData(treeInfo) {
      treeInfo.children.every((item) => {
        item.children.length > 0 && this.getLastChildData(item);
        item.children.length === 0 &&
          this.SET_CURRENT_TREE_INFO(treeInfo.children[0]);
        return;
      });
    },
    /**
     * 获取组价策略配置信息
     */
    async findCombinedPriceConfig() {
      const sequenceNbr = this.$route.query.sequenceNbr as string;
      if (!sequenceNbr) return;
      const { result } =
        await coccMtdsResource_1_coccbuilder_functionMerge_config(sequenceNbr);

      if (result) {
        this.targetBidPrice = Number(result.targetBidPrice);
      }
    },
    getAnaylesisData() {
      coccMtdsResource_project_statistics().then((res) => {
        if (res.status === 200) {
          this.anaylesisData = anaylesisData.map((item) => {
            item.value = res.result.countData[item.code];
            return item;
          });
        }
      });
    },
    async getZjCount() {
      const id = this.projectBidSequenceNbr;
      if (!id) return '';
      this.analysisPieData = [];
      const res = await coccMtdsResource_1_cocc_builder_constructZj(id);
      if (res.status === 200) {
        const result = res.result;
        this.anaylesisInfo = result;
        console.log('combinedPriceAnalysis.vue|377====>', this.anaylesisInfo);
        this.$nextTick(() => {
          this.$set(this.anaylesisInfo, 'neverZj', result.neverZj);
        });
        // for (let i = 0; i < analysisPieData.length; i++) {
        //   let item = analysisPieData[i];
        //   const type = item.code as keyof typeof result;
        //   item.value = result[type];
        //   if (item.value <= 0) continue;
        //   this.analysisPieData.push(item);
        // }
      }
      mergeAnalysis(id).then(({ status, result }) => {
        if (status === 200) {
          this.mergeAnalysisInfo = result;
        }
      });
      // this.$nextTick(() => {
      // this.getAnaylesisData();
      // this.analysisPieEchatr(this.analysisPieData);
      // });
    },
    analysisPieEchatr(analysisPieData: AnalysisDatas[]) {
      const chartDom = document.getElementById(
        'analysis-echatr'
      ) as HTMLElement;
      if (!chartDom) return;
      const chart = echarts.init(chartDom);
      chart.clear();
      const option = {
        title: {
          text: `项目共计 {yellow|${this.anaylesisInfo.total}} 条清单组价，{yellow|${this.anaylesisInfo.neverZj}} 条清单未实行组价`,
          left: '24px',
          textAlign: 'left',
          textStyle: {
            fontSize: '14px',
            color: '#707070',
            rich: {
              yellow: {
                color: '#ff9102',
                fontSize: '14px',
              },
            },
          },
        },
        tooltip: {
          trigger: 'item',
          className: 'analysis-echatr-tooltip',
          formatter: function (params: FormatterPrams) {
            return `<i style="background-color:${params.color};"></i>${params.name}: <span>${params.value}</span>条，占<span>${params.percent}</span>%`;
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            data: analysisPieData,
            emphasis: {
              label: {
                show: true,
              },
            },
            label: {
              show: true,
              formatter: '{b}({yellow|{d}%})',
              rich: {
                yellow: {
                  color: '#ff9102',
                },
              },
            },
            center: ['50%', '170'],
            height: 290,
          },
        ],
      };
      chart.setOption(option);
    },
    getSingleSummary() {
      const sequenceNbr = this.projectBidSequenceNbr;
      if (!sequenceNbr) return '';
      coccMtdsResource_1_project_singlesBySequenceNbrSummary(sequenceNbr).then(
        (res) => {
          if (res.status === 200) {
            this.projectSinglesNames = [];
            this.projectSinglesPrices = [];
            res.result.forEach((item: any) => {
              this.projectSinglesNames.push(item.projectName);
              this.projectSinglesPrices.push(
                parseFloat((item.total / 10000).toFixed(2))
              );
            });
            this.$nextTick(() => {
              this.projectPriceEcharts();
            });
          }
        }
      );
    },
    projectPriceEcharts() {
      const chartDom = document.getElementById(
        'project-price-echarts'
      ) as HTMLElement;
      if (!chartDom) return;
      const myChart = echarts.init(chartDom);
      myChart.clear();
      const option = {
        title: {
          subtext: '单位(万元)',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none',
          },
        },
        calculable: true,
        legend: {
          type: 'scroll',
          data: ['Growth', 'Budget 2011', 'Budget 2012'],
          itemGap: 5,
          itemWidth: 5,
        },
        grid: {
          top: '12%',
          left: '1%',
          right: '10%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: this.projectSinglesNames,
          axisLabel: {
            width: 65,
            interval: 0,
            lineHeight: 14,
            overflow: 'truncate',
            textStyle: {
              color: '#9DADBC', //x轴文字颜色
            },
          },
        },
        yAxis: {
          type: 'value',
        },
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 80,
            height: 15,
          },
          {
            type: 'inside',
            start: 0,
            end: 80,
            height: 15,
          },
          // {
          //   show: true,
          //   yAxisIndex: 0,
          //   filterMode: 'empty',
          //   width: 30,
          //   height: '80%',
          //   showDataShadow: false,
          //   left: '93%',
          // },
        ],
        series: [
          {
            data: this.projectSinglesPrices,
            type: 'bar',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' },
              ]),
            },
            barMaxWidth: 50,
          },
        ],
      };
      myChart.setOption(option);
    },
    close() {
      this.$store.commit('SET_COMBINED_SUCCESS_VISIBLE', false);
    },
    /**
     * type
     * 1 未组价 2 特殊清单项 3 组价推荐
     */
    goCostList(type) {
      if (this.listGenerating) {
        this.$message.warning('项目工程量投标清单正在生成中');
        return;
      }
      if (this.isQuotaLibraryIdentify !== 1) {
        this.$router.push('/ysf/costQuotaLibrary/list');
        this.close();
        return;
      }
      const query = this.$route.query;
      if (!query || !type) return;
      Object.assign(query, {
        type,
        projectBidSequenceNbr: this.projectBidSequenceNbr,
      });
      sessionStorage.setItem('ysf_currentProjectQuery', JSON.stringify(query));
      this.$router.push('/ysf/costQuotaLibrary/quotaAdjustment');
      this.close();
    },
  },
});
